import { useState } from "react";

import "./TypesenseSync.css";
import { Icon } from "../Icon/Icon";
import { useMutation } from "@apollo/client";
import triggerSearchEngineSync from "../../GraphQL/Mutations/triggerSearchEngineSync";

export const TypesenseSync = () => {
  const [syncing, setSyncing] = useState(false);
  const [triggerSyncAction] = useMutation(triggerSearchEngineSync);

  const onTypesenseSync = () => {
    setSyncing(true);
    // Call Typesense sync method on backend
    triggerSyncAction().catch((err) => console.log("Error:", err));
    // Stop animation after 6 minutes
    setTimeout(() => {
      setSyncing(false);
    }, 360000);
  };

  return (
    <div className="TypesenseSync">
      <div className="TypesenseSync__status">
        <div className="TypesenseSync__status-icon">
          <Icon name="syncSucces" width={26} />
        </div>
        <div className="TypesenseSync__status-text">
          <div className="TypesenseSync__status-title">Synkroniser Search Engine</div>
          <div className="TypesenseSync__status-subtitle">Henter alt produkt data fra PIM og opdaterer Typesense</div>
        </div>
      </div>
      <div className="TypesenseSync__button" onClick={onTypesenseSync}>
        <div className={syncing ? "TypesenseSync__icon-animation" : ""}>
          <Icon name="syncNormal" width={28} />
        </div>
        <div className="TypesenseSync__button-label">Opdater</div>
      </div>
    </div>
  );
};
