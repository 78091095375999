import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  JSON: { input: any; output: any; }
};

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type DeleteResponse = {
  __typename?: 'DeleteResponse';
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type GetPresignedUrlResponse = {
  __typename?: 'GetPresignedUrlResponse';
  finalUrl: Scalars['String']['output'];
  presignedUrl: Scalars['String']['output'];
};

/**
 * GroupProduct represents a formatted product with correct parent-child relationships
 * The parent can be derived from either a Variant Group with Products as variants children
 * Or a Product that represents a single product with no variants
 * The type property is used to determine which type of product it is
 */
export type GroupProduct = {
  __typename?: 'GroupProduct';
  category: Scalars['String']['output'];
  customData: Scalars['JSON']['output'];
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  images: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  productData?: Maybe<Scalars['JSON']['output']>;
  productNumber?: Maybe<Scalars['String']['output']>;
  showInShop: Scalars['Boolean']['output'];
  subCategory?: Maybe<Scalars['String']['output']>;
  type: GroupProductType;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  variants?: Maybe<Array<GroupProductVariant>>;
};

export enum GroupProductType {
  Group = 'GROUP',
  Single = 'SINGLE',
  Variant = 'VARIANT'
}

export type GroupProductVariant = {
  __typename?: 'GroupProductVariant';
  category: Scalars['String']['output'];
  customData: Scalars['JSON']['output'];
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  productData: Scalars['JSON']['output'];
  productNumber: Scalars['String']['output'];
  properties: Array<ProductProperty>;
  showInShop: Scalars['Boolean']['output'];
  type: GroupProductType;
};

export type Login = {
  __typename?: 'Login';
  password: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type Metadata = {
  __typename?: 'Metadata';
  id: Scalars['String']['output'];
  jsonPath?: Maybe<Scalars['String']['output']>;
  productDataKey: Scalars['String']['output'];
  title: Scalars['String']['output'];
  trackCompleteness: Scalars['Boolean']['output'];
  type: MetadataType;
  visibility: MetadataVisibility;
};

export enum MetadataType {
  Dropdown = 'DROPDOWN',
  DropdownEditable = 'DROPDOWN_EDITABLE',
  Files = 'FILES',
  Hidden = 'HIDDEN',
  Images = 'IMAGES',
  ReadonlyJson = 'READONLY_JSON',
  Specs = 'SPECS',
  TextboxLarge = 'TEXTBOX_LARGE',
  TextboxMedium = 'TEXTBOX_MEDIUM',
  TextboxSmall = 'TEXTBOX_SMALL',
  TextLine = 'TEXT_LINE',
  Wysiwyg = 'WYSIWYG'
}

export type MetadataVisibility = {
  __typename?: 'MetadataVisibility';
  group: MetadataVisiblityType;
  single: MetadataVisiblityType;
  variant: MetadataVisiblityType;
};

export enum MetadataVisiblityType {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED'
}

export type Mutation = {
  __typename?: 'Mutation';
  addFile?: Maybe<Scalars['Boolean']['output']>;
  addVariantsToGroup: Scalars['String']['output'];
  createProperty: Property;
  createPropertyValue: PropertyValue;
  createUser: User;
  createVariantGroup?: Maybe<VariantGroup>;
  deleteProperty: DeleteResponse;
  deletePropertyValue: DeleteResponse;
  deleteUser?: Maybe<DeleteResponse>;
  deleteVariantGroup: Scalars['String']['output'];
  getPresignedUrl: GetPresignedUrlResponse;
  login?: Maybe<Scalars['String']['output']>;
  removeVariantsFromGroup: Scalars['String']['output'];
  sortVariantInGroup: Scalars['String']['output'];
  triggerProductSync?: Maybe<Scalars['Boolean']['output']>;
  triggerSearchEngineSync?: Maybe<Scalars['Boolean']['output']>;
  updateProduct?: Maybe<Product>;
  updateProductCustomData?: Maybe<Scalars['Boolean']['output']>;
  updateProductPropertyValue?: Maybe<Scalars['Boolean']['output']>;
  updateProperty: Property;
  updatePropertyValue: PropertyValue;
  updateUser?: Maybe<User>;
  updateVariantGroup?: Maybe<VariantGroup>;
  updateVariantGroupProperties: Scalars['String']['output'];
};


export type MutationAddFileArgs = {
  fileUrl: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  prodNo: Scalars['String']['input'];
  productDataKey: Scalars['String']['input'];
  sorting?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationAddVariantsToGroupArgs = {
  variantGroupId: Scalars['ID']['input'];
  variantIds: Array<Scalars['ID']['input']>;
};


export type MutationCreatePropertyArgs = {
  label: Scalars['String']['input'];
};


export type MutationCreatePropertyValueArgs = {
  propertyId: Scalars['String']['input'];
  value: Scalars['String']['input'];
};


export type MutationCreateUserArgs = {
  email: Scalars['String']['input'];
  employeeNumber?: InputMaybe<Scalars['String']['input']>;
  fullName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  role: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationCreateVariantGroupArgs = {
  category: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationDeletePropertyArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeletePropertyValueArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteUserArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDeleteVariantGroupArgs = {
  id: Scalars['ID']['input'];
};


export type MutationGetPresignedUrlArgs = {
  bucketPath: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  fileType: Scalars['String']['input'];
};


export type MutationLoginArgs = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationRemoveVariantsFromGroupArgs = {
  variantGroupId: Scalars['ID']['input'];
  variantIds: Array<Scalars['ID']['input']>;
};


export type MutationSortVariantInGroupArgs = {
  sortOrder: Scalars['Int']['input'];
  variantGroupId: Scalars['ID']['input'];
  variantId: Scalars['ID']['input'];
};


export type MutationUpdateProductArgs = {
  customData?: InputMaybe<Scalars['JSON']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  prodNo?: InputMaybe<Scalars['String']['input']>;
  productData?: InputMaybe<Scalars['JSON']['input']>;
  showInShop?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationUpdateProductCustomDataArgs = {
  metadataKey: Scalars['String']['input'];
  productId: Scalars['ID']['input'];
  value: Scalars['JSON']['input'];
};


export type MutationUpdateProductPropertyValueArgs = {
  productId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
  valueId: Scalars['ID']['input'];
};


export type MutationUpdatePropertyArgs = {
  id: Scalars['String']['input'];
  label: Scalars['String']['input'];
};


export type MutationUpdatePropertyValueArgs = {
  id: Scalars['String']['input'];
  value: Scalars['String']['input'];
};


export type MutationUpdateUserArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  employeeNumber?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateVariantGroupArgs = {
  customData?: InputMaybe<Scalars['JSON']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  showInShop?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationUpdateVariantGroupPropertiesArgs = {
  propertyIds?: InputMaybe<Array<Scalars['String']['input']>>;
  variantGroupId: Scalars['ID']['input'];
};

/** Any Query that retuns product(s), will be cached for 240 seconds */
export type Product = {
  __typename?: 'Product';
  created_at?: Maybe<Scalars['String']['output']>;
  customData: Scalars['JSON']['output'];
  id: Scalars['ID']['output'];
  last_sync?: Maybe<Scalars['String']['output']>;
  prodNo: Scalars['String']['output'];
  productData: Scalars['JSON']['output'];
  properties?: Maybe<Array<ProductProperty>>;
  showInShop: Scalars['Boolean']['output'];
  type: GroupProductType;
  updated_at?: Maybe<Scalars['String']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
};

export type ProductProperty = {
  __typename?: 'ProductProperty';
  label: Scalars['String']['output'];
  propertyId: Scalars['ID']['output'];
  value: Scalars['String']['output'];
  valueId: Scalars['ID']['output'];
};

export type Property = {
  __typename?: 'Property';
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  values: Array<PropertyValue>;
};

export type PropertyValue = {
  __typename?: 'PropertyValue';
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  /** @deprecated use 'metadataValues' instead */
  categories: Array<Maybe<Scalars['String']['output']>>;
  /** @deprecated Use 'products' instead as it is much more efficient */
  getAllProducts?: Maybe<Array<Maybe<Product>>>;
  getAllUsers?: Maybe<Array<Maybe<User>>>;
  /** @deprecated Use 'categories' instead */
  getCategories: Array<Maybe<Scalars['String']['output']>>;
  getFilteredProducts: Array<Product>;
  getMetadata?: Maybe<Array<Maybe<Metadata>>>;
  /** @deprecated use 'product' instead */
  getProductByBarcode?: Maybe<Product>;
  /** @deprecated use 'product' instead */
  getProductByProdNo?: Maybe<Product>;
  getProductKeys: Array<Maybe<Scalars['String']['output']>>;
  getUserById?: Maybe<User>;
  groupProduct: GroupProduct;
  groupProducts: Array<GroupProduct>;
  lastSyncTime: Scalars['String']['output'];
  me?: Maybe<User>;
  metadata: Array<Metadata>;
  metadataValues: Array<Scalars['String']['output']>;
  product?: Maybe<Product>;
  productKeys: Array<Scalars['String']['output']>;
  products: Array<Product>;
  properties: Array<Property>;
  propertiesForGroup: Array<Property>;
  property: Property;
  schedule: Array<Schedule>;
  users: Array<User>;
  variantGroup: VariantGroup;
};


export type QueryGetAllProductsArgs = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetFilteredProductsArgs = {
  category?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetProductByBarcodeArgs = {
  barcode: Scalars['String']['input'];
};


export type QueryGetProductByProdNoArgs = {
  prodNo: Scalars['String']['input'];
};


export type QueryGetUserByIdArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGroupProductArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryGroupProductsArgs = {
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryMetadataValuesArgs = {
  metadataId: Scalars['String']['input'];
};


export type QueryProductArgs = {
  barcode?: InputMaybe<Scalars['String']['input']>;
  productNumber?: InputMaybe<Scalars['String']['input']>;
};


export type QueryProductsArgs = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  productNumbers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPropertiesForGroupArgs = {
  groupId: Scalars['ID']['input'];
};


export type QueryPropertyArgs = {
  id: Scalars['String']['input'];
};


export type QueryVariantGroupArgs = {
  id: Scalars['ID']['input'];
};

export enum Role {
  Admin = 'ADMIN',
  User = 'USER'
}

export type Schedule = {
  __typename?: 'Schedule';
  day: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  time: Scalars['String']['output'];
};

export type SuccessResponse = {
  __typename?: 'SuccessResponse';
  success: Scalars['Boolean']['output'];
};

export type User = {
  __typename?: 'User';
  email: Scalars['String']['output'];
  employeeNumber?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  password?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  username: Scalars['String']['output'];
};

export type VariantGroup = {
  __typename?: 'VariantGroup';
  category: Scalars['String']['output'];
  created_at: Scalars['String']['output'];
  customData: Scalars['JSON']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  showInShop: Scalars['Boolean']['output'];
  updatedBy: Scalars['String']['output'];
  updated_at: Scalars['String']['output'];
};

export type GetPresignedUrlMutationVariables = Exact<{
  bucketPath: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  fileType: Scalars['String']['input'];
}>;


export type GetPresignedUrlMutation = { __typename?: 'Mutation', getPresignedUrl: { __typename?: 'GetPresignedUrlResponse', presignedUrl: string, finalUrl: string } };

export type VariantPropertiesSelectorQueryVariables = Exact<{ [key: string]: never; }>;


export type VariantPropertiesSelectorQuery = { __typename?: 'Query', properties: Array<{ __typename?: 'Property', id: string, label: string }> };

export type VariantSelectorQueryVariables = Exact<{
  categories?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
}>;


export type VariantSelectorQuery = { __typename?: 'Query', products: Array<{ __typename?: 'Product', id: string, prodNo: string, productData: any, customData: any, type: GroupProductType }> };

export type DropdownMetaFieldQueryVariables = Exact<{
  metadataId: Scalars['String']['input'];
}>;


export type DropdownMetaFieldQuery = { __typename?: 'Query', metadataValues: Array<string> };

export type EditCategoryQueryVariables = Exact<{
  categories: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type EditCategoryQuery = { __typename?: 'Query', metadata: Array<{ __typename?: 'Metadata', id: string, title: string, productDataKey: string, type: MetadataType, trackCompleteness: boolean, visibility: { __typename?: 'MetadataVisibility', group: MetadataVisiblityType, single: MetadataVisiblityType, variant: MetadataVisiblityType } }>, groupProducts: Array<{ __typename?: 'GroupProduct', id: string, name: string, productNumber?: string | null, category: string, imageUrl?: string | null, images: Array<string>, customData: any, productData?: any | null, type: GroupProductType, showInShop: boolean, variants?: Array<{ __typename?: 'GroupProductVariant', id: string, name: string, productNumber: string, customData: any, productData: any, imageUrl?: string | null, type: GroupProductType, showInShop: boolean }> | null }> };

export type EditGroupQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type EditGroupQuery = { __typename?: 'Query', metadata: Array<{ __typename?: 'Metadata', id: string, title: string, productDataKey: string, type: MetadataType, visibility: { __typename?: 'MetadataVisibility', group: MetadataVisiblityType, single: MetadataVisiblityType, variant: MetadataVisiblityType } }>, groupProduct: { __typename?: 'GroupProduct', id: string, customData: any, showInShop: boolean, updatedBy?: string | null, updatedAt?: string | null, variants?: Array<{ __typename?: 'GroupProductVariant', id: string, name: string, productNumber: string, imageUrl?: string | null, productData: any, properties: Array<{ __typename?: 'ProductProperty', value: string, valueId: string, propertyId: string }> }> | null }, propertiesForGroup: Array<{ __typename?: 'Property', id: string, label: string, values: Array<{ __typename?: 'PropertyValue', id: string, value: string }> }> };

export type EditProductQueryVariables = Exact<{
  productNumber: Scalars['String']['input'];
}>;


export type EditProductQuery = { __typename?: 'Query', metadata: Array<{ __typename?: 'Metadata', id: string, title: string, productDataKey: string, type: MetadataType, jsonPath?: string | null, visibility: { __typename?: 'MetadataVisibility', group: MetadataVisiblityType, single: MetadataVisiblityType, variant: MetadataVisiblityType } }>, product?: { __typename?: 'Product', id: string, productData: any, prodNo: string, customData: any, showInShop: boolean, updated_by?: string | null, updated_at?: string | null, type: GroupProductType, properties?: Array<{ __typename?: 'ProductProperty', propertyId: string, valueId: string, label: string, value: string }> | null } | null };

export type AddNewPropertyValueMutationVariables = Exact<{
  propertyId: Scalars['String']['input'];
  value: Scalars['String']['input'];
}>;


export type AddNewPropertyValueMutation = { __typename?: 'Mutation', createPropertyValue: { __typename?: 'PropertyValue', id: string } };

export type AddVariantsToGroupMutationVariables = Exact<{
  variantGroupId: Scalars['ID']['input'];
  variantIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type AddVariantsToGroupMutation = { __typename?: 'Mutation', addVariantsToGroup: string };

export type DeleteVariantGroupMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteVariantGroupMutation = { __typename?: 'Mutation', deleteVariantGroup: string };

export type RemoveVariantsFromGroupMutationVariables = Exact<{
  variantGroupId: Scalars['ID']['input'];
  variantIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type RemoveVariantsFromGroupMutation = { __typename?: 'Mutation', removeVariantsFromGroup: string };

export type SortVariantInGroupMutationVariables = Exact<{
  variantGroupId: Scalars['ID']['input'];
  variantId: Scalars['ID']['input'];
  sortOrder: Scalars['Int']['input'];
}>;


export type SortVariantInGroupMutation = { __typename?: 'Mutation', sortVariantInGroup: string };

export type UpdateProductMutationVariables = Exact<{
  prodNo: Scalars['String']['input'];
  customData?: InputMaybe<Scalars['JSON']['input']>;
  showInShop?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdateProductMutation = { __typename?: 'Mutation', updateProduct?: { __typename?: 'Product', id: string } | null };

export type UpdateProductCustomDataMutationVariables = Exact<{
  productId: Scalars['ID']['input'];
  metadataKey: Scalars['String']['input'];
  value: Scalars['JSON']['input'];
}>;


export type UpdateProductCustomDataMutation = { __typename?: 'Mutation', updateProductCustomData?: boolean | null };

export type UpdateProductPropertyValueMutationVariables = Exact<{
  productId: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
  valueId: Scalars['ID']['input'];
}>;


export type UpdateProductPropertyValueMutation = { __typename?: 'Mutation', updateProductPropertyValue?: boolean | null };

export type UpdateVariantGroupMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  customData?: InputMaybe<Scalars['JSON']['input']>;
  showInShop?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdateVariantGroupMutation = { __typename?: 'Mutation', updateVariantGroup?: { __typename?: 'VariantGroup', id: string } | null };

export type UpdateVariantGroupPropertiesMutationVariables = Exact<{
  variantGroupId: Scalars['ID']['input'];
  propertyIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;


export type UpdateVariantGroupPropertiesMutation = { __typename?: 'Mutation', updateVariantGroupProperties: string };

export type AddFileMutationVariables = Exact<{
  fileUrl: Scalars['String']['input'];
  prodNo: Scalars['String']['input'];
  sorting?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  productDataKey: Scalars['String']['input'];
}>;


export type AddFileMutation = { __typename?: 'Mutation', addFile?: boolean | null };

export type OverviewQueryVariables = Exact<{ [key: string]: never; }>;


export type OverviewQuery = { __typename?: 'Query', categories: Array<string | null>, metadata: Array<{ __typename?: 'Metadata', id: string, title: string, productDataKey: string, type: MetadataType, trackCompleteness: boolean, visibility: { __typename?: 'MetadataVisibility', group: MetadataVisiblityType, single: MetadataVisiblityType, variant: MetadataVisiblityType } }>, groupProducts: Array<{ __typename?: 'GroupProduct', id: string, name: string, productNumber?: string | null, category: string, imageUrl?: string | null, images: Array<string>, customData: any, productData?: any | null, type: GroupProductType, showInShop: boolean, variants?: Array<{ __typename?: 'GroupProductVariant', id: string, name: string, productNumber: string, customData: any, productData: any, imageUrl?: string | null, type: GroupProductType, showInShop: boolean }> | null }> };

export type UpdateGroupShowInShopMutationVariables = Exact<{
  groupId: Scalars['ID']['input'];
  showInShop: Scalars['Boolean']['input'];
}>;


export type UpdateGroupShowInShopMutation = { __typename?: 'Mutation', updateVariantGroup?: { __typename?: 'VariantGroup', id: string } | null };

export type UpdateProductShowInShopMutationVariables = Exact<{
  productId: Scalars['ID']['input'];
  showInShop: Scalars['Boolean']['input'];
}>;


export type UpdateProductShowInShopMutation = { __typename?: 'Mutation', updateProduct?: { __typename?: 'Product', id: string } | null };

export type DeletePropertyMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeletePropertyMutation = { __typename?: 'Mutation', deleteProperty: { __typename?: 'DeleteResponse', id: string, message: string, success: boolean } };

export type PropertiesQueryVariables = Exact<{ [key: string]: never; }>;


export type PropertiesQuery = { __typename?: 'Query', properties: Array<{ __typename?: 'Property', id: string, label: string }> };

export type PropertiesEditMutationVariables = Exact<{
  id: Scalars['String']['input'];
  label: Scalars['String']['input'];
}>;


export type PropertiesEditMutation = { __typename?: 'Mutation', updateProperty: { __typename?: 'Property', id: string, label: string } };

export type PropertiesEditQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type PropertiesEditQueryQuery = { __typename?: 'Query', property: { __typename?: 'Property', id: string, label: string, values: Array<{ __typename?: 'PropertyValue', id: string, value: string }> } };

export type PropertiesNewMutationVariables = Exact<{
  label: Scalars['String']['input'];
}>;


export type PropertiesNewMutation = { __typename?: 'Mutation', createProperty: { __typename?: 'Property', id: string, label: string } };

export type DeletePropertyValueMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeletePropertyValueMutation = { __typename?: 'Mutation', deletePropertyValue: { __typename?: 'DeleteResponse', id: string, message: string, success: boolean } };

export type PropertiesValuesQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type PropertiesValuesQuery = { __typename?: 'Query', property: { __typename?: 'Property', id: string, label: string, values: Array<{ __typename?: 'PropertyValue', id: string, value: string }> } };

export type PropertiesValuesEditMutationVariables = Exact<{
  id: Scalars['String']['input'];
  value: Scalars['String']['input'];
}>;


export type PropertiesValuesEditMutation = { __typename?: 'Mutation', updatePropertyValue: { __typename?: 'PropertyValue', id: string, value: string } };

export type PropertiesValuesEditQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type PropertiesValuesEditQueryQuery = { __typename?: 'Query', property: { __typename?: 'Property', values: Array<{ __typename?: 'PropertyValue', id: string, value: string }> } };

export type PropertiesValuesNewMutationVariables = Exact<{
  propertyId: Scalars['String']['input'];
  value: Scalars['String']['input'];
}>;


export type PropertiesValuesNewMutation = { __typename?: 'Mutation', createPropertyValue: { __typename?: 'PropertyValue', id: string, value: string } };

export type PropertiesValuesNewQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type PropertiesValuesNewQueryQuery = { __typename?: 'Query', property: { __typename?: 'Property', id: string, label: string } };

export type CreateVariantGroupMutationVariables = Exact<{
  name: Scalars['String']['input'];
  category: Scalars['String']['input'];
}>;


export type CreateVariantGroupMutation = { __typename?: 'Mutation', createVariantGroup?: { __typename?: 'VariantGroup', id: string } | null };

export type VariantGroupNewQueryVariables = Exact<{ [key: string]: never; }>;


export type VariantGroupNewQuery = { __typename?: 'Query', categories: Array<string | null> };

export type DeleteUserMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser?: { __typename?: 'DeleteResponse', id: string, message: string, success: boolean } | null };

export type SendSearchEngineSyncMutationVariables = Exact<{ [key: string]: never; }>;


export type SendSearchEngineSyncMutation = { __typename?: 'Mutation', triggerSearchEngineSync?: boolean | null };

export type SendSyncEventMutationVariables = Exact<{ [key: string]: never; }>;


export type SendSyncEventMutation = { __typename?: 'Mutation', triggerProductSync?: boolean | null };

export type LoginMutationVariables = Exact<{
  username: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login?: string | null };

export type CategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CategoriesQuery = { __typename?: 'Query', categories: Array<string | null> };

export type GetFilteredProductsQueryVariables = Exact<{
  category?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetFilteredProductsQuery = { __typename?: 'Query', getFilteredProducts: Array<{ __typename?: 'Product', id: string, prodNo: string, productData: any, customData: any, updated_by?: string | null, last_sync?: string | null, created_at?: string | null, updated_at?: string | null }> };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, email: string, employeeNumber?: string | null, fullName?: string | null, role?: string | null, username: string } | null };

export type QueryQueryVariables = Exact<{ [key: string]: never; }>;


export type QueryQuery = { __typename?: 'Query', productKeys: Array<string> };

export type LastSyncTimeQueryVariables = Exact<{ [key: string]: never; }>;


export type LastSyncTimeQuery = { __typename?: 'Query', lastSyncTime: string };

export type MetadataQueryVariables = Exact<{ [key: string]: never; }>;


export type MetadataQuery = { __typename?: 'Query', metadata: Array<{ __typename?: 'Metadata', id: string, title: string, productDataKey: string, type: MetadataType, trackCompleteness: boolean }> };

export type ProductKeysQueryVariables = Exact<{ [key: string]: never; }>;


export type ProductKeysQuery = { __typename?: 'Query', productKeys: Array<string> };

export type ProductQueryVariables = Exact<{
  productNumber?: InputMaybe<Scalars['String']['input']>;
  barcode?: InputMaybe<Scalars['String']['input']>;
}>;


export type ProductQuery = { __typename?: 'Query', product?: { __typename?: 'Product', id: string, prodNo: string, productData: any, customData: any, updated_by?: string | null, created_at?: string | null, updated_at?: string | null, last_sync?: string | null, properties?: Array<{ __typename?: 'ProductProperty', value: string, label: string, valueId: string, propertyId: string }> | null } | null };

export type ProductsQueryVariables = Exact<{
  categories?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  productNumbers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
}>;


export type ProductsQuery = { __typename?: 'Query', products: Array<{ __typename?: 'Product', id: string, prodNo: string, productData: any, customData: any, updated_by?: string | null, last_sync?: string | null, created_at?: string | null, updated_at?: string | null }> };

export type ScheduleQueryVariables = Exact<{ [key: string]: never; }>;


export type ScheduleQuery = { __typename?: 'Query', schedule: Array<{ __typename?: 'Schedule', id: string, day: string, time: string }> };

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', id: string, fullName?: string | null, username: string, password?: string | null, email: string, employeeNumber?: string | null, role?: string | null }> };


export const GetPresignedUrlDocument = gql`
    mutation GetPresignedUrl($bucketPath: String!, $fileName: String!, $fileType: String!) {
  getPresignedUrl(
    bucketPath: $bucketPath
    fileName: $fileName
    fileType: $fileType
  ) {
    presignedUrl
    finalUrl
  }
}
    `;
export type GetPresignedUrlMutationFn = Apollo.MutationFunction<GetPresignedUrlMutation, GetPresignedUrlMutationVariables>;

/**
 * __useGetPresignedUrlMutation__
 *
 * To run a mutation, you first call `useGetPresignedUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetPresignedUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getPresignedUrlMutation, { data, loading, error }] = useGetPresignedUrlMutation({
 *   variables: {
 *      bucketPath: // value for 'bucketPath'
 *      fileName: // value for 'fileName'
 *      fileType: // value for 'fileType'
 *   },
 * });
 */
export function useGetPresignedUrlMutation(baseOptions?: Apollo.MutationHookOptions<GetPresignedUrlMutation, GetPresignedUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetPresignedUrlMutation, GetPresignedUrlMutationVariables>(GetPresignedUrlDocument, options);
      }
export type GetPresignedUrlMutationHookResult = ReturnType<typeof useGetPresignedUrlMutation>;
export type GetPresignedUrlMutationResult = Apollo.MutationResult<GetPresignedUrlMutation>;
export type GetPresignedUrlMutationOptions = Apollo.BaseMutationOptions<GetPresignedUrlMutation, GetPresignedUrlMutationVariables>;
export const VariantPropertiesSelectorDocument = gql`
    query VariantPropertiesSelector {
  properties {
    id
    label
  }
}
    `;

/**
 * __useVariantPropertiesSelectorQuery__
 *
 * To run a query within a React component, call `useVariantPropertiesSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useVariantPropertiesSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVariantPropertiesSelectorQuery({
 *   variables: {
 *   },
 * });
 */
export function useVariantPropertiesSelectorQuery(baseOptions?: Apollo.QueryHookOptions<VariantPropertiesSelectorQuery, VariantPropertiesSelectorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VariantPropertiesSelectorQuery, VariantPropertiesSelectorQueryVariables>(VariantPropertiesSelectorDocument, options);
      }
export function useVariantPropertiesSelectorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VariantPropertiesSelectorQuery, VariantPropertiesSelectorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VariantPropertiesSelectorQuery, VariantPropertiesSelectorQueryVariables>(VariantPropertiesSelectorDocument, options);
        }
export type VariantPropertiesSelectorQueryHookResult = ReturnType<typeof useVariantPropertiesSelectorQuery>;
export type VariantPropertiesSelectorLazyQueryHookResult = ReturnType<typeof useVariantPropertiesSelectorLazyQuery>;
export type VariantPropertiesSelectorQueryResult = Apollo.QueryResult<VariantPropertiesSelectorQuery, VariantPropertiesSelectorQueryVariables>;
export const VariantSelectorDocument = gql`
    query VariantSelector($categories: [String]) {
  products(categories: $categories) {
    id
    prodNo
    productData
    customData
    type
  }
}
    `;

/**
 * __useVariantSelectorQuery__
 *
 * To run a query within a React component, call `useVariantSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useVariantSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVariantSelectorQuery({
 *   variables: {
 *      categories: // value for 'categories'
 *   },
 * });
 */
export function useVariantSelectorQuery(baseOptions?: Apollo.QueryHookOptions<VariantSelectorQuery, VariantSelectorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VariantSelectorQuery, VariantSelectorQueryVariables>(VariantSelectorDocument, options);
      }
export function useVariantSelectorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VariantSelectorQuery, VariantSelectorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VariantSelectorQuery, VariantSelectorQueryVariables>(VariantSelectorDocument, options);
        }
export type VariantSelectorQueryHookResult = ReturnType<typeof useVariantSelectorQuery>;
export type VariantSelectorLazyQueryHookResult = ReturnType<typeof useVariantSelectorLazyQuery>;
export type VariantSelectorQueryResult = Apollo.QueryResult<VariantSelectorQuery, VariantSelectorQueryVariables>;
export const DropdownMetaFieldDocument = gql`
    query DropdownMetaField($metadataId: String!) {
  metadataValues(metadataId: $metadataId)
}
    `;

/**
 * __useDropdownMetaFieldQuery__
 *
 * To run a query within a React component, call `useDropdownMetaFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useDropdownMetaFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDropdownMetaFieldQuery({
 *   variables: {
 *      metadataId: // value for 'metadataId'
 *   },
 * });
 */
export function useDropdownMetaFieldQuery(baseOptions: Apollo.QueryHookOptions<DropdownMetaFieldQuery, DropdownMetaFieldQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DropdownMetaFieldQuery, DropdownMetaFieldQueryVariables>(DropdownMetaFieldDocument, options);
      }
export function useDropdownMetaFieldLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DropdownMetaFieldQuery, DropdownMetaFieldQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DropdownMetaFieldQuery, DropdownMetaFieldQueryVariables>(DropdownMetaFieldDocument, options);
        }
export type DropdownMetaFieldQueryHookResult = ReturnType<typeof useDropdownMetaFieldQuery>;
export type DropdownMetaFieldLazyQueryHookResult = ReturnType<typeof useDropdownMetaFieldLazyQuery>;
export type DropdownMetaFieldQueryResult = Apollo.QueryResult<DropdownMetaFieldQuery, DropdownMetaFieldQueryVariables>;
export const EditCategoryDocument = gql`
    query EditCategory($categories: [String!]!) {
  metadata {
    id
    title
    productDataKey
    type
    trackCompleteness
    visibility {
      group
      single
      variant
    }
  }
  groupProducts(categories: $categories) {
    id
    name
    productNumber
    category
    imageUrl
    images
    customData
    productData
    type
    showInShop
    variants {
      id
      name
      productNumber
      customData
      productData
      imageUrl
      type
      showInShop
    }
  }
}
    `;

/**
 * __useEditCategoryQuery__
 *
 * To run a query within a React component, call `useEditCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditCategoryQuery({
 *   variables: {
 *      categories: // value for 'categories'
 *   },
 * });
 */
export function useEditCategoryQuery(baseOptions: Apollo.QueryHookOptions<EditCategoryQuery, EditCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditCategoryQuery, EditCategoryQueryVariables>(EditCategoryDocument, options);
      }
export function useEditCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditCategoryQuery, EditCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditCategoryQuery, EditCategoryQueryVariables>(EditCategoryDocument, options);
        }
export type EditCategoryQueryHookResult = ReturnType<typeof useEditCategoryQuery>;
export type EditCategoryLazyQueryHookResult = ReturnType<typeof useEditCategoryLazyQuery>;
export type EditCategoryQueryResult = Apollo.QueryResult<EditCategoryQuery, EditCategoryQueryVariables>;
export const EditGroupDocument = gql`
    query EditGroup($id: ID!) {
  metadata {
    id
    title
    productDataKey
    type
    visibility {
      group
      single
      variant
    }
  }
  groupProduct(id: $id) {
    id
    customData
    showInShop
    updatedBy
    updatedAt
    variants {
      id
      name
      productNumber
      imageUrl
      properties {
        value
        valueId
        propertyId
      }
      productData
    }
  }
  propertiesForGroup(groupId: $id) {
    id
    label
    values {
      id
      value
    }
  }
}
    `;

/**
 * __useEditGroupQuery__
 *
 * To run a query within a React component, call `useEditGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditGroupQuery(baseOptions: Apollo.QueryHookOptions<EditGroupQuery, EditGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditGroupQuery, EditGroupQueryVariables>(EditGroupDocument, options);
      }
export function useEditGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditGroupQuery, EditGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditGroupQuery, EditGroupQueryVariables>(EditGroupDocument, options);
        }
export type EditGroupQueryHookResult = ReturnType<typeof useEditGroupQuery>;
export type EditGroupLazyQueryHookResult = ReturnType<typeof useEditGroupLazyQuery>;
export type EditGroupQueryResult = Apollo.QueryResult<EditGroupQuery, EditGroupQueryVariables>;
export const EditProductDocument = gql`
    query EditProduct($productNumber: String!) {
  metadata {
    id
    title
    productDataKey
    type
    visibility {
      group
      single
      variant
    }
    jsonPath
  }
  product(productNumber: $productNumber) {
    id
    productData
    prodNo
    customData
    showInShop
    updated_by
    updated_at
    properties {
      propertyId
      valueId
      label
      value
    }
    type
  }
}
    `;

/**
 * __useEditProductQuery__
 *
 * To run a query within a React component, call `useEditProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditProductQuery({
 *   variables: {
 *      productNumber: // value for 'productNumber'
 *   },
 * });
 */
export function useEditProductQuery(baseOptions: Apollo.QueryHookOptions<EditProductQuery, EditProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditProductQuery, EditProductQueryVariables>(EditProductDocument, options);
      }
export function useEditProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditProductQuery, EditProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditProductQuery, EditProductQueryVariables>(EditProductDocument, options);
        }
export type EditProductQueryHookResult = ReturnType<typeof useEditProductQuery>;
export type EditProductLazyQueryHookResult = ReturnType<typeof useEditProductLazyQuery>;
export type EditProductQueryResult = Apollo.QueryResult<EditProductQuery, EditProductQueryVariables>;
export const AddNewPropertyValueDocument = gql`
    mutation AddNewPropertyValue($propertyId: String!, $value: String!) {
  createPropertyValue(propertyId: $propertyId, value: $value) {
    id
  }
}
    `;
export type AddNewPropertyValueMutationFn = Apollo.MutationFunction<AddNewPropertyValueMutation, AddNewPropertyValueMutationVariables>;

/**
 * __useAddNewPropertyValueMutation__
 *
 * To run a mutation, you first call `useAddNewPropertyValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNewPropertyValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNewPropertyValueMutation, { data, loading, error }] = useAddNewPropertyValueMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useAddNewPropertyValueMutation(baseOptions?: Apollo.MutationHookOptions<AddNewPropertyValueMutation, AddNewPropertyValueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddNewPropertyValueMutation, AddNewPropertyValueMutationVariables>(AddNewPropertyValueDocument, options);
      }
export type AddNewPropertyValueMutationHookResult = ReturnType<typeof useAddNewPropertyValueMutation>;
export type AddNewPropertyValueMutationResult = Apollo.MutationResult<AddNewPropertyValueMutation>;
export type AddNewPropertyValueMutationOptions = Apollo.BaseMutationOptions<AddNewPropertyValueMutation, AddNewPropertyValueMutationVariables>;
export const AddVariantsToGroupDocument = gql`
    mutation AddVariantsToGroup($variantGroupId: ID!, $variantIds: [ID!]!) {
  addVariantsToGroup(variantGroupId: $variantGroupId, variantIds: $variantIds)
}
    `;
export type AddVariantsToGroupMutationFn = Apollo.MutationFunction<AddVariantsToGroupMutation, AddVariantsToGroupMutationVariables>;

/**
 * __useAddVariantsToGroupMutation__
 *
 * To run a mutation, you first call `useAddVariantsToGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVariantsToGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVariantsToGroupMutation, { data, loading, error }] = useAddVariantsToGroupMutation({
 *   variables: {
 *      variantGroupId: // value for 'variantGroupId'
 *      variantIds: // value for 'variantIds'
 *   },
 * });
 */
export function useAddVariantsToGroupMutation(baseOptions?: Apollo.MutationHookOptions<AddVariantsToGroupMutation, AddVariantsToGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddVariantsToGroupMutation, AddVariantsToGroupMutationVariables>(AddVariantsToGroupDocument, options);
      }
export type AddVariantsToGroupMutationHookResult = ReturnType<typeof useAddVariantsToGroupMutation>;
export type AddVariantsToGroupMutationResult = Apollo.MutationResult<AddVariantsToGroupMutation>;
export type AddVariantsToGroupMutationOptions = Apollo.BaseMutationOptions<AddVariantsToGroupMutation, AddVariantsToGroupMutationVariables>;
export const DeleteVariantGroupDocument = gql`
    mutation DeleteVariantGroup($id: ID!) {
  deleteVariantGroup(id: $id)
}
    `;
export type DeleteVariantGroupMutationFn = Apollo.MutationFunction<DeleteVariantGroupMutation, DeleteVariantGroupMutationVariables>;

/**
 * __useDeleteVariantGroupMutation__
 *
 * To run a mutation, you first call `useDeleteVariantGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVariantGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVariantGroupMutation, { data, loading, error }] = useDeleteVariantGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteVariantGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVariantGroupMutation, DeleteVariantGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteVariantGroupMutation, DeleteVariantGroupMutationVariables>(DeleteVariantGroupDocument, options);
      }
export type DeleteVariantGroupMutationHookResult = ReturnType<typeof useDeleteVariantGroupMutation>;
export type DeleteVariantGroupMutationResult = Apollo.MutationResult<DeleteVariantGroupMutation>;
export type DeleteVariantGroupMutationOptions = Apollo.BaseMutationOptions<DeleteVariantGroupMutation, DeleteVariantGroupMutationVariables>;
export const RemoveVariantsFromGroupDocument = gql`
    mutation RemoveVariantsFromGroup($variantGroupId: ID!, $variantIds: [ID!]!) {
  removeVariantsFromGroup(
    variantGroupId: $variantGroupId
    variantIds: $variantIds
  )
}
    `;
export type RemoveVariantsFromGroupMutationFn = Apollo.MutationFunction<RemoveVariantsFromGroupMutation, RemoveVariantsFromGroupMutationVariables>;

/**
 * __useRemoveVariantsFromGroupMutation__
 *
 * To run a mutation, you first call `useRemoveVariantsFromGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveVariantsFromGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeVariantsFromGroupMutation, { data, loading, error }] = useRemoveVariantsFromGroupMutation({
 *   variables: {
 *      variantGroupId: // value for 'variantGroupId'
 *      variantIds: // value for 'variantIds'
 *   },
 * });
 */
export function useRemoveVariantsFromGroupMutation(baseOptions?: Apollo.MutationHookOptions<RemoveVariantsFromGroupMutation, RemoveVariantsFromGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveVariantsFromGroupMutation, RemoveVariantsFromGroupMutationVariables>(RemoveVariantsFromGroupDocument, options);
      }
export type RemoveVariantsFromGroupMutationHookResult = ReturnType<typeof useRemoveVariantsFromGroupMutation>;
export type RemoveVariantsFromGroupMutationResult = Apollo.MutationResult<RemoveVariantsFromGroupMutation>;
export type RemoveVariantsFromGroupMutationOptions = Apollo.BaseMutationOptions<RemoveVariantsFromGroupMutation, RemoveVariantsFromGroupMutationVariables>;
export const SortVariantInGroupDocument = gql`
    mutation SortVariantInGroup($variantGroupId: ID!, $variantId: ID!, $sortOrder: Int!) {
  sortVariantInGroup(
    variantGroupId: $variantGroupId
    variantId: $variantId
    sortOrder: $sortOrder
  )
}
    `;
export type SortVariantInGroupMutationFn = Apollo.MutationFunction<SortVariantInGroupMutation, SortVariantInGroupMutationVariables>;

/**
 * __useSortVariantInGroupMutation__
 *
 * To run a mutation, you first call `useSortVariantInGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSortVariantInGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sortVariantInGroupMutation, { data, loading, error }] = useSortVariantInGroupMutation({
 *   variables: {
 *      variantGroupId: // value for 'variantGroupId'
 *      variantId: // value for 'variantId'
 *      sortOrder: // value for 'sortOrder'
 *   },
 * });
 */
export function useSortVariantInGroupMutation(baseOptions?: Apollo.MutationHookOptions<SortVariantInGroupMutation, SortVariantInGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SortVariantInGroupMutation, SortVariantInGroupMutationVariables>(SortVariantInGroupDocument, options);
      }
export type SortVariantInGroupMutationHookResult = ReturnType<typeof useSortVariantInGroupMutation>;
export type SortVariantInGroupMutationResult = Apollo.MutationResult<SortVariantInGroupMutation>;
export type SortVariantInGroupMutationOptions = Apollo.BaseMutationOptions<SortVariantInGroupMutation, SortVariantInGroupMutationVariables>;
export const UpdateProductDocument = gql`
    mutation UpdateProduct($prodNo: String!, $customData: JSON, $showInShop: Boolean) {
  updateProduct(prodNo: $prodNo, customData: $customData, showInShop: $showInShop) {
    id
  }
}
    `;
export type UpdateProductMutationFn = Apollo.MutationFunction<UpdateProductMutation, UpdateProductMutationVariables>;

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      prodNo: // value for 'prodNo'
 *      customData: // value for 'customData'
 *      showInShop: // value for 'showInShop'
 *   },
 * });
 */
export function useUpdateProductMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductMutation, UpdateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, options);
      }
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>;
export type UpdateProductMutationResult = Apollo.MutationResult<UpdateProductMutation>;
export type UpdateProductMutationOptions = Apollo.BaseMutationOptions<UpdateProductMutation, UpdateProductMutationVariables>;
export const UpdateProductCustomDataDocument = gql`
    mutation UpdateProductCustomData($productId: ID!, $metadataKey: String!, $value: JSON!) {
  updateProductCustomData(
    productId: $productId
    metadataKey: $metadataKey
    value: $value
  )
}
    `;
export type UpdateProductCustomDataMutationFn = Apollo.MutationFunction<UpdateProductCustomDataMutation, UpdateProductCustomDataMutationVariables>;

/**
 * __useUpdateProductCustomDataMutation__
 *
 * To run a mutation, you first call `useUpdateProductCustomDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductCustomDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductCustomDataMutation, { data, loading, error }] = useUpdateProductCustomDataMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      metadataKey: // value for 'metadataKey'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateProductCustomDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductCustomDataMutation, UpdateProductCustomDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductCustomDataMutation, UpdateProductCustomDataMutationVariables>(UpdateProductCustomDataDocument, options);
      }
export type UpdateProductCustomDataMutationHookResult = ReturnType<typeof useUpdateProductCustomDataMutation>;
export type UpdateProductCustomDataMutationResult = Apollo.MutationResult<UpdateProductCustomDataMutation>;
export type UpdateProductCustomDataMutationOptions = Apollo.BaseMutationOptions<UpdateProductCustomDataMutation, UpdateProductCustomDataMutationVariables>;
export const UpdateProductPropertyValueDocument = gql`
    mutation UpdateProductPropertyValue($productId: ID!, $propertyId: ID!, $valueId: ID!) {
  updateProductPropertyValue(
    productId: $productId
    propertyId: $propertyId
    valueId: $valueId
  )
}
    `;
export type UpdateProductPropertyValueMutationFn = Apollo.MutationFunction<UpdateProductPropertyValueMutation, UpdateProductPropertyValueMutationVariables>;

/**
 * __useUpdateProductPropertyValueMutation__
 *
 * To run a mutation, you first call `useUpdateProductPropertyValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductPropertyValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductPropertyValueMutation, { data, loading, error }] = useUpdateProductPropertyValueMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      propertyId: // value for 'propertyId'
 *      valueId: // value for 'valueId'
 *   },
 * });
 */
export function useUpdateProductPropertyValueMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductPropertyValueMutation, UpdateProductPropertyValueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductPropertyValueMutation, UpdateProductPropertyValueMutationVariables>(UpdateProductPropertyValueDocument, options);
      }
export type UpdateProductPropertyValueMutationHookResult = ReturnType<typeof useUpdateProductPropertyValueMutation>;
export type UpdateProductPropertyValueMutationResult = Apollo.MutationResult<UpdateProductPropertyValueMutation>;
export type UpdateProductPropertyValueMutationOptions = Apollo.BaseMutationOptions<UpdateProductPropertyValueMutation, UpdateProductPropertyValueMutationVariables>;
export const UpdateVariantGroupDocument = gql`
    mutation UpdateVariantGroup($id: ID!, $customData: JSON, $showInShop: Boolean) {
  updateVariantGroup(id: $id, customData: $customData, showInShop: $showInShop) {
    id
  }
}
    `;
export type UpdateVariantGroupMutationFn = Apollo.MutationFunction<UpdateVariantGroupMutation, UpdateVariantGroupMutationVariables>;

/**
 * __useUpdateVariantGroupMutation__
 *
 * To run a mutation, you first call `useUpdateVariantGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVariantGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVariantGroupMutation, { data, loading, error }] = useUpdateVariantGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      customData: // value for 'customData'
 *      showInShop: // value for 'showInShop'
 *   },
 * });
 */
export function useUpdateVariantGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVariantGroupMutation, UpdateVariantGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVariantGroupMutation, UpdateVariantGroupMutationVariables>(UpdateVariantGroupDocument, options);
      }
export type UpdateVariantGroupMutationHookResult = ReturnType<typeof useUpdateVariantGroupMutation>;
export type UpdateVariantGroupMutationResult = Apollo.MutationResult<UpdateVariantGroupMutation>;
export type UpdateVariantGroupMutationOptions = Apollo.BaseMutationOptions<UpdateVariantGroupMutation, UpdateVariantGroupMutationVariables>;
export const UpdateVariantGroupPropertiesDocument = gql`
    mutation UpdateVariantGroupProperties($variantGroupId: ID!, $propertyIds: [String!]) {
  updateVariantGroupProperties(
    variantGroupId: $variantGroupId
    propertyIds: $propertyIds
  )
}
    `;
export type UpdateVariantGroupPropertiesMutationFn = Apollo.MutationFunction<UpdateVariantGroupPropertiesMutation, UpdateVariantGroupPropertiesMutationVariables>;

/**
 * __useUpdateVariantGroupPropertiesMutation__
 *
 * To run a mutation, you first call `useUpdateVariantGroupPropertiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVariantGroupPropertiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVariantGroupPropertiesMutation, { data, loading, error }] = useUpdateVariantGroupPropertiesMutation({
 *   variables: {
 *      variantGroupId: // value for 'variantGroupId'
 *      propertyIds: // value for 'propertyIds'
 *   },
 * });
 */
export function useUpdateVariantGroupPropertiesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVariantGroupPropertiesMutation, UpdateVariantGroupPropertiesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVariantGroupPropertiesMutation, UpdateVariantGroupPropertiesMutationVariables>(UpdateVariantGroupPropertiesDocument, options);
      }
export type UpdateVariantGroupPropertiesMutationHookResult = ReturnType<typeof useUpdateVariantGroupPropertiesMutation>;
export type UpdateVariantGroupPropertiesMutationResult = Apollo.MutationResult<UpdateVariantGroupPropertiesMutation>;
export type UpdateVariantGroupPropertiesMutationOptions = Apollo.BaseMutationOptions<UpdateVariantGroupPropertiesMutation, UpdateVariantGroupPropertiesMutationVariables>;
export const AddFileDocument = gql`
    mutation AddFile($fileUrl: String!, $prodNo: String!, $sorting: Int, $name: String, $productDataKey: String!) {
  addFile(
    fileUrl: $fileUrl
    prodNo: $prodNo
    sorting: $sorting
    name: $name
    productDataKey: $productDataKey
  )
}
    `;
export type AddFileMutationFn = Apollo.MutationFunction<AddFileMutation, AddFileMutationVariables>;

/**
 * __useAddFileMutation__
 *
 * To run a mutation, you first call `useAddFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFileMutation, { data, loading, error }] = useAddFileMutation({
 *   variables: {
 *      fileUrl: // value for 'fileUrl'
 *      prodNo: // value for 'prodNo'
 *      sorting: // value for 'sorting'
 *      name: // value for 'name'
 *      productDataKey: // value for 'productDataKey'
 *   },
 * });
 */
export function useAddFileMutation(baseOptions?: Apollo.MutationHookOptions<AddFileMutation, AddFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddFileMutation, AddFileMutationVariables>(AddFileDocument, options);
      }
export type AddFileMutationHookResult = ReturnType<typeof useAddFileMutation>;
export type AddFileMutationResult = Apollo.MutationResult<AddFileMutation>;
export type AddFileMutationOptions = Apollo.BaseMutationOptions<AddFileMutation, AddFileMutationVariables>;
export const OverviewDocument = gql`
    query Overview {
  categories
  metadata {
    id
    title
    productDataKey
    type
    trackCompleteness
    visibility {
      group
      single
      variant
    }
  }
  groupProducts {
    id
    name
    productNumber
    category
    imageUrl
    images
    customData
    productData
    type
    showInShop
    variants {
      id
      name
      productNumber
      customData
      productData
      imageUrl
      type
      showInShop
    }
  }
}
    `;

/**
 * __useOverviewQuery__
 *
 * To run a query within a React component, call `useOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOverviewQuery({
 *   variables: {
 *   },
 * });
 */
export function useOverviewQuery(baseOptions?: Apollo.QueryHookOptions<OverviewQuery, OverviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OverviewQuery, OverviewQueryVariables>(OverviewDocument, options);
      }
export function useOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OverviewQuery, OverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OverviewQuery, OverviewQueryVariables>(OverviewDocument, options);
        }
export type OverviewQueryHookResult = ReturnType<typeof useOverviewQuery>;
export type OverviewLazyQueryHookResult = ReturnType<typeof useOverviewLazyQuery>;
export type OverviewQueryResult = Apollo.QueryResult<OverviewQuery, OverviewQueryVariables>;
export const UpdateGroupShowInShopDocument = gql`
    mutation UpdateGroupShowInShop($groupId: ID!, $showInShop: Boolean!) {
  updateVariantGroup(id: $groupId, showInShop: $showInShop) {
    id
  }
}
    `;
export type UpdateGroupShowInShopMutationFn = Apollo.MutationFunction<UpdateGroupShowInShopMutation, UpdateGroupShowInShopMutationVariables>;

/**
 * __useUpdateGroupShowInShopMutation__
 *
 * To run a mutation, you first call `useUpdateGroupShowInShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupShowInShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupShowInShopMutation, { data, loading, error }] = useUpdateGroupShowInShopMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      showInShop: // value for 'showInShop'
 *   },
 * });
 */
export function useUpdateGroupShowInShopMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGroupShowInShopMutation, UpdateGroupShowInShopMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGroupShowInShopMutation, UpdateGroupShowInShopMutationVariables>(UpdateGroupShowInShopDocument, options);
      }
export type UpdateGroupShowInShopMutationHookResult = ReturnType<typeof useUpdateGroupShowInShopMutation>;
export type UpdateGroupShowInShopMutationResult = Apollo.MutationResult<UpdateGroupShowInShopMutation>;
export type UpdateGroupShowInShopMutationOptions = Apollo.BaseMutationOptions<UpdateGroupShowInShopMutation, UpdateGroupShowInShopMutationVariables>;
export const UpdateProductShowInShopDocument = gql`
    mutation UpdateProductShowInShop($productId: ID!, $showInShop: Boolean!) {
  updateProduct(id: $productId, showInShop: $showInShop) {
    id
  }
}
    `;
export type UpdateProductShowInShopMutationFn = Apollo.MutationFunction<UpdateProductShowInShopMutation, UpdateProductShowInShopMutationVariables>;

/**
 * __useUpdateProductShowInShopMutation__
 *
 * To run a mutation, you first call `useUpdateProductShowInShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductShowInShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductShowInShopMutation, { data, loading, error }] = useUpdateProductShowInShopMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      showInShop: // value for 'showInShop'
 *   },
 * });
 */
export function useUpdateProductShowInShopMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductShowInShopMutation, UpdateProductShowInShopMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductShowInShopMutation, UpdateProductShowInShopMutationVariables>(UpdateProductShowInShopDocument, options);
      }
export type UpdateProductShowInShopMutationHookResult = ReturnType<typeof useUpdateProductShowInShopMutation>;
export type UpdateProductShowInShopMutationResult = Apollo.MutationResult<UpdateProductShowInShopMutation>;
export type UpdateProductShowInShopMutationOptions = Apollo.BaseMutationOptions<UpdateProductShowInShopMutation, UpdateProductShowInShopMutationVariables>;
export const DeletePropertyDocument = gql`
    mutation DeleteProperty($id: String!) {
  deleteProperty(id: $id) {
    id
    message
    success
  }
}
    `;
export type DeletePropertyMutationFn = Apollo.MutationFunction<DeletePropertyMutation, DeletePropertyMutationVariables>;

/**
 * __useDeletePropertyMutation__
 *
 * To run a mutation, you first call `useDeletePropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePropertyMutation, { data, loading, error }] = useDeletePropertyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePropertyMutation(baseOptions?: Apollo.MutationHookOptions<DeletePropertyMutation, DeletePropertyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePropertyMutation, DeletePropertyMutationVariables>(DeletePropertyDocument, options);
      }
export type DeletePropertyMutationHookResult = ReturnType<typeof useDeletePropertyMutation>;
export type DeletePropertyMutationResult = Apollo.MutationResult<DeletePropertyMutation>;
export type DeletePropertyMutationOptions = Apollo.BaseMutationOptions<DeletePropertyMutation, DeletePropertyMutationVariables>;
export const PropertiesDocument = gql`
    query Properties {
  properties {
    id
    label
  }
}
    `;

/**
 * __usePropertiesQuery__
 *
 * To run a query within a React component, call `usePropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertiesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePropertiesQuery(baseOptions?: Apollo.QueryHookOptions<PropertiesQuery, PropertiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PropertiesQuery, PropertiesQueryVariables>(PropertiesDocument, options);
      }
export function usePropertiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PropertiesQuery, PropertiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PropertiesQuery, PropertiesQueryVariables>(PropertiesDocument, options);
        }
export type PropertiesQueryHookResult = ReturnType<typeof usePropertiesQuery>;
export type PropertiesLazyQueryHookResult = ReturnType<typeof usePropertiesLazyQuery>;
export type PropertiesQueryResult = Apollo.QueryResult<PropertiesQuery, PropertiesQueryVariables>;
export const PropertiesEditDocument = gql`
    mutation PropertiesEdit($id: String!, $label: String!) {
  updateProperty(id: $id, label: $label) {
    id
    label
  }
}
    `;
export type PropertiesEditMutationFn = Apollo.MutationFunction<PropertiesEditMutation, PropertiesEditMutationVariables>;

/**
 * __usePropertiesEditMutation__
 *
 * To run a mutation, you first call `usePropertiesEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePropertiesEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [propertiesEditMutation, { data, loading, error }] = usePropertiesEditMutation({
 *   variables: {
 *      id: // value for 'id'
 *      label: // value for 'label'
 *   },
 * });
 */
export function usePropertiesEditMutation(baseOptions?: Apollo.MutationHookOptions<PropertiesEditMutation, PropertiesEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PropertiesEditMutation, PropertiesEditMutationVariables>(PropertiesEditDocument, options);
      }
export type PropertiesEditMutationHookResult = ReturnType<typeof usePropertiesEditMutation>;
export type PropertiesEditMutationResult = Apollo.MutationResult<PropertiesEditMutation>;
export type PropertiesEditMutationOptions = Apollo.BaseMutationOptions<PropertiesEditMutation, PropertiesEditMutationVariables>;
export const PropertiesEditQueryDocument = gql`
    query PropertiesEditQuery($id: String!) {
  property(id: $id) {
    id
    label
    values {
      id
      value
    }
  }
}
    `;

/**
 * __usePropertiesEditQueryQuery__
 *
 * To run a query within a React component, call `usePropertiesEditQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertiesEditQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertiesEditQueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePropertiesEditQueryQuery(baseOptions: Apollo.QueryHookOptions<PropertiesEditQueryQuery, PropertiesEditQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PropertiesEditQueryQuery, PropertiesEditQueryQueryVariables>(PropertiesEditQueryDocument, options);
      }
export function usePropertiesEditQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PropertiesEditQueryQuery, PropertiesEditQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PropertiesEditQueryQuery, PropertiesEditQueryQueryVariables>(PropertiesEditQueryDocument, options);
        }
export type PropertiesEditQueryQueryHookResult = ReturnType<typeof usePropertiesEditQueryQuery>;
export type PropertiesEditQueryLazyQueryHookResult = ReturnType<typeof usePropertiesEditQueryLazyQuery>;
export type PropertiesEditQueryQueryResult = Apollo.QueryResult<PropertiesEditQueryQuery, PropertiesEditQueryQueryVariables>;
export const PropertiesNewDocument = gql`
    mutation PropertiesNew($label: String!) {
  createProperty(label: $label) {
    id
    label
  }
}
    `;
export type PropertiesNewMutationFn = Apollo.MutationFunction<PropertiesNewMutation, PropertiesNewMutationVariables>;

/**
 * __usePropertiesNewMutation__
 *
 * To run a mutation, you first call `usePropertiesNewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePropertiesNewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [propertiesNewMutation, { data, loading, error }] = usePropertiesNewMutation({
 *   variables: {
 *      label: // value for 'label'
 *   },
 * });
 */
export function usePropertiesNewMutation(baseOptions?: Apollo.MutationHookOptions<PropertiesNewMutation, PropertiesNewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PropertiesNewMutation, PropertiesNewMutationVariables>(PropertiesNewDocument, options);
      }
export type PropertiesNewMutationHookResult = ReturnType<typeof usePropertiesNewMutation>;
export type PropertiesNewMutationResult = Apollo.MutationResult<PropertiesNewMutation>;
export type PropertiesNewMutationOptions = Apollo.BaseMutationOptions<PropertiesNewMutation, PropertiesNewMutationVariables>;
export const DeletePropertyValueDocument = gql`
    mutation DeletePropertyValue($id: String!) {
  deletePropertyValue(id: $id) {
    id
    message
    success
  }
}
    `;
export type DeletePropertyValueMutationFn = Apollo.MutationFunction<DeletePropertyValueMutation, DeletePropertyValueMutationVariables>;

/**
 * __useDeletePropertyValueMutation__
 *
 * To run a mutation, you first call `useDeletePropertyValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePropertyValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePropertyValueMutation, { data, loading, error }] = useDeletePropertyValueMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePropertyValueMutation(baseOptions?: Apollo.MutationHookOptions<DeletePropertyValueMutation, DeletePropertyValueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePropertyValueMutation, DeletePropertyValueMutationVariables>(DeletePropertyValueDocument, options);
      }
export type DeletePropertyValueMutationHookResult = ReturnType<typeof useDeletePropertyValueMutation>;
export type DeletePropertyValueMutationResult = Apollo.MutationResult<DeletePropertyValueMutation>;
export type DeletePropertyValueMutationOptions = Apollo.BaseMutationOptions<DeletePropertyValueMutation, DeletePropertyValueMutationVariables>;
export const PropertiesValuesDocument = gql`
    query PropertiesValues($id: String!) {
  property(id: $id) {
    id
    label
    values {
      id
      value
    }
  }
}
    `;

/**
 * __usePropertiesValuesQuery__
 *
 * To run a query within a React component, call `usePropertiesValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertiesValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertiesValuesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePropertiesValuesQuery(baseOptions: Apollo.QueryHookOptions<PropertiesValuesQuery, PropertiesValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PropertiesValuesQuery, PropertiesValuesQueryVariables>(PropertiesValuesDocument, options);
      }
export function usePropertiesValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PropertiesValuesQuery, PropertiesValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PropertiesValuesQuery, PropertiesValuesQueryVariables>(PropertiesValuesDocument, options);
        }
export type PropertiesValuesQueryHookResult = ReturnType<typeof usePropertiesValuesQuery>;
export type PropertiesValuesLazyQueryHookResult = ReturnType<typeof usePropertiesValuesLazyQuery>;
export type PropertiesValuesQueryResult = Apollo.QueryResult<PropertiesValuesQuery, PropertiesValuesQueryVariables>;
export const PropertiesValuesEditDocument = gql`
    mutation PropertiesValuesEdit($id: String!, $value: String!) {
  updatePropertyValue(id: $id, value: $value) {
    id
    value
  }
}
    `;
export type PropertiesValuesEditMutationFn = Apollo.MutationFunction<PropertiesValuesEditMutation, PropertiesValuesEditMutationVariables>;

/**
 * __usePropertiesValuesEditMutation__
 *
 * To run a mutation, you first call `usePropertiesValuesEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePropertiesValuesEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [propertiesValuesEditMutation, { data, loading, error }] = usePropertiesValuesEditMutation({
 *   variables: {
 *      id: // value for 'id'
 *      value: // value for 'value'
 *   },
 * });
 */
export function usePropertiesValuesEditMutation(baseOptions?: Apollo.MutationHookOptions<PropertiesValuesEditMutation, PropertiesValuesEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PropertiesValuesEditMutation, PropertiesValuesEditMutationVariables>(PropertiesValuesEditDocument, options);
      }
export type PropertiesValuesEditMutationHookResult = ReturnType<typeof usePropertiesValuesEditMutation>;
export type PropertiesValuesEditMutationResult = Apollo.MutationResult<PropertiesValuesEditMutation>;
export type PropertiesValuesEditMutationOptions = Apollo.BaseMutationOptions<PropertiesValuesEditMutation, PropertiesValuesEditMutationVariables>;
export const PropertiesValuesEditQueryDocument = gql`
    query PropertiesValuesEditQuery($id: String!) {
  property(id: $id) {
    values {
      id
      value
    }
  }
}
    `;

/**
 * __usePropertiesValuesEditQueryQuery__
 *
 * To run a query within a React component, call `usePropertiesValuesEditQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertiesValuesEditQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertiesValuesEditQueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePropertiesValuesEditQueryQuery(baseOptions: Apollo.QueryHookOptions<PropertiesValuesEditQueryQuery, PropertiesValuesEditQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PropertiesValuesEditQueryQuery, PropertiesValuesEditQueryQueryVariables>(PropertiesValuesEditQueryDocument, options);
      }
export function usePropertiesValuesEditQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PropertiesValuesEditQueryQuery, PropertiesValuesEditQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PropertiesValuesEditQueryQuery, PropertiesValuesEditQueryQueryVariables>(PropertiesValuesEditQueryDocument, options);
        }
export type PropertiesValuesEditQueryQueryHookResult = ReturnType<typeof usePropertiesValuesEditQueryQuery>;
export type PropertiesValuesEditQueryLazyQueryHookResult = ReturnType<typeof usePropertiesValuesEditQueryLazyQuery>;
export type PropertiesValuesEditQueryQueryResult = Apollo.QueryResult<PropertiesValuesEditQueryQuery, PropertiesValuesEditQueryQueryVariables>;
export const PropertiesValuesNewDocument = gql`
    mutation PropertiesValuesNew($propertyId: String!, $value: String!) {
  createPropertyValue(propertyId: $propertyId, value: $value) {
    id
    value
  }
}
    `;
export type PropertiesValuesNewMutationFn = Apollo.MutationFunction<PropertiesValuesNewMutation, PropertiesValuesNewMutationVariables>;

/**
 * __usePropertiesValuesNewMutation__
 *
 * To run a mutation, you first call `usePropertiesValuesNewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePropertiesValuesNewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [propertiesValuesNewMutation, { data, loading, error }] = usePropertiesValuesNewMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      value: // value for 'value'
 *   },
 * });
 */
export function usePropertiesValuesNewMutation(baseOptions?: Apollo.MutationHookOptions<PropertiesValuesNewMutation, PropertiesValuesNewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PropertiesValuesNewMutation, PropertiesValuesNewMutationVariables>(PropertiesValuesNewDocument, options);
      }
export type PropertiesValuesNewMutationHookResult = ReturnType<typeof usePropertiesValuesNewMutation>;
export type PropertiesValuesNewMutationResult = Apollo.MutationResult<PropertiesValuesNewMutation>;
export type PropertiesValuesNewMutationOptions = Apollo.BaseMutationOptions<PropertiesValuesNewMutation, PropertiesValuesNewMutationVariables>;
export const PropertiesValuesNewQueryDocument = gql`
    query PropertiesValuesNewQuery($id: String!) {
  property(id: $id) {
    id
    label
  }
}
    `;

/**
 * __usePropertiesValuesNewQueryQuery__
 *
 * To run a query within a React component, call `usePropertiesValuesNewQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertiesValuesNewQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertiesValuesNewQueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePropertiesValuesNewQueryQuery(baseOptions: Apollo.QueryHookOptions<PropertiesValuesNewQueryQuery, PropertiesValuesNewQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PropertiesValuesNewQueryQuery, PropertiesValuesNewQueryQueryVariables>(PropertiesValuesNewQueryDocument, options);
      }
export function usePropertiesValuesNewQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PropertiesValuesNewQueryQuery, PropertiesValuesNewQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PropertiesValuesNewQueryQuery, PropertiesValuesNewQueryQueryVariables>(PropertiesValuesNewQueryDocument, options);
        }
export type PropertiesValuesNewQueryQueryHookResult = ReturnType<typeof usePropertiesValuesNewQueryQuery>;
export type PropertiesValuesNewQueryLazyQueryHookResult = ReturnType<typeof usePropertiesValuesNewQueryLazyQuery>;
export type PropertiesValuesNewQueryQueryResult = Apollo.QueryResult<PropertiesValuesNewQueryQuery, PropertiesValuesNewQueryQueryVariables>;
export const CreateVariantGroupDocument = gql`
    mutation CreateVariantGroup($name: String!, $category: String!) {
  createVariantGroup(name: $name, category: $category) {
    id
  }
}
    `;
export type CreateVariantGroupMutationFn = Apollo.MutationFunction<CreateVariantGroupMutation, CreateVariantGroupMutationVariables>;

/**
 * __useCreateVariantGroupMutation__
 *
 * To run a mutation, you first call `useCreateVariantGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVariantGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVariantGroupMutation, { data, loading, error }] = useCreateVariantGroupMutation({
 *   variables: {
 *      name: // value for 'name'
 *      category: // value for 'category'
 *   },
 * });
 */
export function useCreateVariantGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateVariantGroupMutation, CreateVariantGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVariantGroupMutation, CreateVariantGroupMutationVariables>(CreateVariantGroupDocument, options);
      }
export type CreateVariantGroupMutationHookResult = ReturnType<typeof useCreateVariantGroupMutation>;
export type CreateVariantGroupMutationResult = Apollo.MutationResult<CreateVariantGroupMutation>;
export type CreateVariantGroupMutationOptions = Apollo.BaseMutationOptions<CreateVariantGroupMutation, CreateVariantGroupMutationVariables>;
export const VariantGroupNewDocument = gql`
    query VariantGroupNew {
  categories
}
    `;

/**
 * __useVariantGroupNewQuery__
 *
 * To run a query within a React component, call `useVariantGroupNewQuery` and pass it any options that fit your needs.
 * When your component renders, `useVariantGroupNewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVariantGroupNewQuery({
 *   variables: {
 *   },
 * });
 */
export function useVariantGroupNewQuery(baseOptions?: Apollo.QueryHookOptions<VariantGroupNewQuery, VariantGroupNewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VariantGroupNewQuery, VariantGroupNewQueryVariables>(VariantGroupNewDocument, options);
      }
export function useVariantGroupNewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VariantGroupNewQuery, VariantGroupNewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VariantGroupNewQuery, VariantGroupNewQueryVariables>(VariantGroupNewDocument, options);
        }
export type VariantGroupNewQueryHookResult = ReturnType<typeof useVariantGroupNewQuery>;
export type VariantGroupNewLazyQueryHookResult = ReturnType<typeof useVariantGroupNewLazyQuery>;
export type VariantGroupNewQueryResult = Apollo.QueryResult<VariantGroupNewQuery, VariantGroupNewQueryVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($id: String) {
  deleteUser(id: $id) {
    id
    message
    success
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const SendSearchEngineSyncDocument = gql`
    mutation sendSearchEngineSync {
  triggerSearchEngineSync
}
    `;
export type SendSearchEngineSyncMutationFn = Apollo.MutationFunction<SendSearchEngineSyncMutation, SendSearchEngineSyncMutationVariables>;

/**
 * __useSendSearchEngineSyncMutation__
 *
 * To run a mutation, you first call `useSendSearchEngineSyncMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSearchEngineSyncMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSearchEngineSyncMutation, { data, loading, error }] = useSendSearchEngineSyncMutation({
 *   variables: {
 *   },
 * });
 */
export function useSendSearchEngineSyncMutation(baseOptions?: Apollo.MutationHookOptions<SendSearchEngineSyncMutation, SendSearchEngineSyncMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendSearchEngineSyncMutation, SendSearchEngineSyncMutationVariables>(SendSearchEngineSyncDocument, options);
      }
export type SendSearchEngineSyncMutationHookResult = ReturnType<typeof useSendSearchEngineSyncMutation>;
export type SendSearchEngineSyncMutationResult = Apollo.MutationResult<SendSearchEngineSyncMutation>;
export type SendSearchEngineSyncMutationOptions = Apollo.BaseMutationOptions<SendSearchEngineSyncMutation, SendSearchEngineSyncMutationVariables>;
export const SendSyncEventDocument = gql`
    mutation sendSyncEvent {
  triggerProductSync
}
    `;
export type SendSyncEventMutationFn = Apollo.MutationFunction<SendSyncEventMutation, SendSyncEventMutationVariables>;

/**
 * __useSendSyncEventMutation__
 *
 * To run a mutation, you first call `useSendSyncEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSyncEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSyncEventMutation, { data, loading, error }] = useSendSyncEventMutation({
 *   variables: {
 *   },
 * });
 */
export function useSendSyncEventMutation(baseOptions?: Apollo.MutationHookOptions<SendSyncEventMutation, SendSyncEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendSyncEventMutation, SendSyncEventMutationVariables>(SendSyncEventDocument, options);
      }
export type SendSyncEventMutationHookResult = ReturnType<typeof useSendSyncEventMutation>;
export type SendSyncEventMutationResult = Apollo.MutationResult<SendSyncEventMutation>;
export type SendSyncEventMutationOptions = Apollo.BaseMutationOptions<SendSyncEventMutation, SendSyncEventMutationVariables>;
export const LoginDocument = gql`
    mutation Login($username: String!, $password: String!) {
  login(username: $username, password: $password)
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const CategoriesDocument = gql`
    query categories {
  categories
}
    `;

/**
 * __useCategoriesQuery__
 *
 * To run a query within a React component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
      }
export function useCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
        }
export type CategoriesQueryHookResult = ReturnType<typeof useCategoriesQuery>;
export type CategoriesLazyQueryHookResult = ReturnType<typeof useCategoriesLazyQuery>;
export type CategoriesQueryResult = Apollo.QueryResult<CategoriesQuery, CategoriesQueryVariables>;
export const GetFilteredProductsDocument = gql`
    query GetFilteredProducts($category: String, $filter: String) {
  getFilteredProducts(category: $category, filter: $filter) {
    id
    prodNo
    productData
    customData
    updated_by
    last_sync
    created_at
    updated_at
  }
}
    `;

/**
 * __useGetFilteredProductsQuery__
 *
 * To run a query within a React component, call `useGetFilteredProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilteredProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilteredProductsQuery({
 *   variables: {
 *      category: // value for 'category'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetFilteredProductsQuery(baseOptions?: Apollo.QueryHookOptions<GetFilteredProductsQuery, GetFilteredProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFilteredProductsQuery, GetFilteredProductsQueryVariables>(GetFilteredProductsDocument, options);
      }
export function useGetFilteredProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFilteredProductsQuery, GetFilteredProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFilteredProductsQuery, GetFilteredProductsQueryVariables>(GetFilteredProductsDocument, options);
        }
export type GetFilteredProductsQueryHookResult = ReturnType<typeof useGetFilteredProductsQuery>;
export type GetFilteredProductsLazyQueryHookResult = ReturnType<typeof useGetFilteredProductsLazyQuery>;
export type GetFilteredProductsQueryResult = Apollo.QueryResult<GetFilteredProductsQuery, GetFilteredProductsQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    id
    email
    employeeNumber
    fullName
    role
    username
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const QueryDocument = gql`
    query Query {
  productKeys
}
    `;

/**
 * __useQueryQuery__
 *
 * To run a query within a React component, call `useQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useQueryQuery(baseOptions?: Apollo.QueryHookOptions<QueryQuery, QueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryQuery, QueryQueryVariables>(QueryDocument, options);
      }
export function useQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryQuery, QueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryQuery, QueryQueryVariables>(QueryDocument, options);
        }
export type QueryQueryHookResult = ReturnType<typeof useQueryQuery>;
export type QueryLazyQueryHookResult = ReturnType<typeof useQueryLazyQuery>;
export type QueryQueryResult = Apollo.QueryResult<QueryQuery, QueryQueryVariables>;
export const LastSyncTimeDocument = gql`
    query LastSyncTime {
  lastSyncTime
}
    `;

/**
 * __useLastSyncTimeQuery__
 *
 * To run a query within a React component, call `useLastSyncTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useLastSyncTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLastSyncTimeQuery({
 *   variables: {
 *   },
 * });
 */
export function useLastSyncTimeQuery(baseOptions?: Apollo.QueryHookOptions<LastSyncTimeQuery, LastSyncTimeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LastSyncTimeQuery, LastSyncTimeQueryVariables>(LastSyncTimeDocument, options);
      }
export function useLastSyncTimeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LastSyncTimeQuery, LastSyncTimeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LastSyncTimeQuery, LastSyncTimeQueryVariables>(LastSyncTimeDocument, options);
        }
export type LastSyncTimeQueryHookResult = ReturnType<typeof useLastSyncTimeQuery>;
export type LastSyncTimeLazyQueryHookResult = ReturnType<typeof useLastSyncTimeLazyQuery>;
export type LastSyncTimeQueryResult = Apollo.QueryResult<LastSyncTimeQuery, LastSyncTimeQueryVariables>;
export const MetadataDocument = gql`
    query Metadata {
  metadata {
    id
    title
    productDataKey
    type
    trackCompleteness
  }
}
    `;

/**
 * __useMetadataQuery__
 *
 * To run a query within a React component, call `useMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMetadataQuery({
 *   variables: {
 *   },
 * });
 */
export function useMetadataQuery(baseOptions?: Apollo.QueryHookOptions<MetadataQuery, MetadataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MetadataQuery, MetadataQueryVariables>(MetadataDocument, options);
      }
export function useMetadataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MetadataQuery, MetadataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MetadataQuery, MetadataQueryVariables>(MetadataDocument, options);
        }
export type MetadataQueryHookResult = ReturnType<typeof useMetadataQuery>;
export type MetadataLazyQueryHookResult = ReturnType<typeof useMetadataLazyQuery>;
export type MetadataQueryResult = Apollo.QueryResult<MetadataQuery, MetadataQueryVariables>;
export const ProductKeysDocument = gql`
    query ProductKeys {
  productKeys
}
    `;

/**
 * __useProductKeysQuery__
 *
 * To run a query within a React component, call `useProductKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductKeysQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductKeysQuery(baseOptions?: Apollo.QueryHookOptions<ProductKeysQuery, ProductKeysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductKeysQuery, ProductKeysQueryVariables>(ProductKeysDocument, options);
      }
export function useProductKeysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductKeysQuery, ProductKeysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductKeysQuery, ProductKeysQueryVariables>(ProductKeysDocument, options);
        }
export type ProductKeysQueryHookResult = ReturnType<typeof useProductKeysQuery>;
export type ProductKeysLazyQueryHookResult = ReturnType<typeof useProductKeysLazyQuery>;
export type ProductKeysQueryResult = Apollo.QueryResult<ProductKeysQuery, ProductKeysQueryVariables>;
export const ProductDocument = gql`
    query Product($productNumber: String, $barcode: String) {
  product(productNumber: $productNumber, barcode: $barcode) {
    id
    prodNo
    productData
    customData
    updated_by
    created_at
    updated_at
    last_sync
    properties {
      value
      label
      valueId
      propertyId
    }
  }
}
    `;

/**
 * __useProductQuery__
 *
 * To run a query within a React component, call `useProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductQuery({
 *   variables: {
 *      productNumber: // value for 'productNumber'
 *      barcode: // value for 'barcode'
 *   },
 * });
 */
export function useProductQuery(baseOptions?: Apollo.QueryHookOptions<ProductQuery, ProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
      }
export function useProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductQuery, ProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
        }
export type ProductQueryHookResult = ReturnType<typeof useProductQuery>;
export type ProductLazyQueryHookResult = ReturnType<typeof useProductLazyQuery>;
export type ProductQueryResult = Apollo.QueryResult<ProductQuery, ProductQueryVariables>;
export const ProductsDocument = gql`
    query Products($categories: [String], $offset: Int, $limit: Int, $searchTerm: String, $productNumbers: [String]) {
  products(
    categories: $categories
    offset: $offset
    limit: $limit
    searchTerm: $searchTerm
    productNumbers: $productNumbers
  ) {
    id
    prodNo
    productData
    customData
    updated_by
    last_sync
    created_at
    updated_at
  }
}
    `;

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *      categories: // value for 'categories'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      searchTerm: // value for 'searchTerm'
 *      productNumbers: // value for 'productNumbers'
 *   },
 * });
 */
export function useProductsQuery(baseOptions?: Apollo.QueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
      }
export function useProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
        }
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>;
export type ProductsQueryResult = Apollo.QueryResult<ProductsQuery, ProductsQueryVariables>;
export const ScheduleDocument = gql`
    query Schedule {
  schedule {
    id
    day
    time
  }
}
    `;

/**
 * __useScheduleQuery__
 *
 * To run a query within a React component, call `useScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleQuery({
 *   variables: {
 *   },
 * });
 */
export function useScheduleQuery(baseOptions?: Apollo.QueryHookOptions<ScheduleQuery, ScheduleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScheduleQuery, ScheduleQueryVariables>(ScheduleDocument, options);
      }
export function useScheduleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScheduleQuery, ScheduleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScheduleQuery, ScheduleQueryVariables>(ScheduleDocument, options);
        }
export type ScheduleQueryHookResult = ReturnType<typeof useScheduleQuery>;
export type ScheduleLazyQueryHookResult = ReturnType<typeof useScheduleLazyQuery>;
export type ScheduleQueryResult = Apollo.QueryResult<ScheduleQuery, ScheduleQueryVariables>;
export const UsersDocument = gql`
    query Users {
  users {
    id
    fullName
    username
    password
    email
    employeeNumber
    role
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;