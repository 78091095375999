/**
 * Define a way to configure the display of product status
 * Giving a color for the indicator, an original status code to match against
 * and a text override to display instead of the original status text
 */
type ProductStatusString = {
  statusCode: string;
  textOverride: string;
  color: string;
};

type ProductStatusConfig = {
  statusStrings: ProductStatusString[];
};

// Define our colors
const green = "#00D154";
const yellow = "#DF8F0B";
const grey = "#999";
const red = "#dc5d6c";

// Define our config
const productStatusConfig: ProductStatusConfig = {
  statusStrings: [
    {
      color: green,
      statusCode: "30",
      textOverride: "Frigivet til salg",
    },
    {
      color: yellow,
      statusCode: "35",
      textOverride: "Skaffevare",
    },
    {
      color: grey,
      statusCode: "90",
      textOverride: "Udgået",
    },
    {
      color: grey,
      statusCode: "85",
      textOverride: "Udgår v. 0",
    },
    {
      color: yellow,
      statusCode: "20",
      textOverride: "Under oprettelse",
    },
    {
      color: yellow,
      statusCode: "10",
      textOverride: "Under oprettelse",
    },
    {
      color: red,
      statusCode: "99",
      textOverride: "Spærret",
    },
  ],
};

/**
 * Gets a color and text value for product status display.
 */
export const getProductStatusConfig = (status: {
  statusCode: string;
  statusText: string;
}): ProductStatusString => {
  // Find the config for the status code
  const config = productStatusConfig.statusStrings.find(
    (statusString) => statusString.statusCode === status.statusCode
  );

  // Return the raw value if no config is found
  if (!config) {
    return {
      color: grey,
      statusCode: status.statusCode,
      textOverride: status.statusText,
    };
  }

  // Return the found config
  return config;
};
