import "./SyncSettings.css";
import { Sync } from "../../Components/Sync/Sync";
import { SyncPlan } from "../../Components/SyncPlan/SyncPlan";
import { Loading } from "../../Components/Loading/Loading";
import { useScheduleQuery } from "../../Generated/graphql";
import { TypesenseSync } from "../../Components/TypesenseSync/TypesenseSync";

export const SyncSettings = () => {
  const { data, loading } = useScheduleQuery();

  return (
    <div className="SyncSettings">
      <div className="SyncSettings__content">
        <div className="SyncSettings__lastsync">
          <Sync />
        </div>

        <div className="SyncSettings__typesense">
          <TypesenseSync />
        </div>

        <div className="SyncSettings__poa">
          {loading && <Loading />}
          {data && <SyncPlan plan={data?.schedule} deleteTask={() => {}} />}
        </div>
      </div>
    </div>
  );
};
